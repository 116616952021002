<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0, 0, 0, .5)'}"/>
      </Section>
      <Section limiter>
        <Row justify="between">
          <AccountMenu v-if="$laptop"
                       class="grid-md-4 grid-lg-4"/>
          <AccountContent class="grid-sm-full grid-md-8 grid-lg-8"/>
        </Row>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountSettingsView',
  data() {
    return {
      navigation: [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/account',
        title: 'Личный кабинет',
      }],
    };
  },
  watch: {
    $user() {
      this.$redirectIfGuest();
    },
  },
};
</script>
